import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaInstagram, FaSkype, FaVimeo, FaGithub, FaAmazon, FaGooglePlay, FaFacebook, FaYoutube } from "react-icons/fa";


const Footer = ({ showBelow }) => {
    const footerQuery = useStaticQuery(graphql`
        query footerQuery {
            allFootermenuJson {
                nodes {
                    line1
                    line2
                }
            }
        }
    `);

    const footerInfo = footerQuery.allFootermenuJson.nodes

    const [show, setShow] = useState(showBelow ? false : true)

    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            if (!show) setShow(true)
        } else {
            if (show) setShow(false)
        }
    }

    useEffect(() => {
        if (showBelow) {
            window.addEventListener(`scroll`, handleScroll)
            return () => window.removeEventListener(`scroll`, handleScroll)
        }
    })

    const handleClick = () => {
        window[`scrollTo`]({ top: 0, behavior: `smooth` })
    }
    return (
        <footer className="rn-footer-area bg-color-black pt--90 pb--60">
            <div className="footer-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--30 mt_sm--30 wow fadeInDown" data-wow-delay="200ms" data-wow-duration="0.1s">
                            <div className="ft-info">
                                <p>{footerInfo[0].line1} <br />{footerInfo[0].line2}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--30 mt_sm--30 wow fadeInDown" data-wow-delay="200ms" data-wow-duration="0.2s">
                            <div className="ft-info">
                                <p>{footerInfo[1].line1} <br />{footerInfo[1].line2}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--30 mt_sm--30 wow fadeInDown" data-wow-delay="200ms" data-wow-duration="0.4s">
                            <div className="ft-info">
                                <p>{footerInfo[2].line1} <br />{footerInfo[2].line2}</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--30 mt_sm--30 wow fadeInDown" data-wow-delay="200ms" data-wow-duration="0.6s">
                            <div className="ft-info">
                                <ul className="social-share">
                                    <li><a href="https://github.com/gquattromani"><FaGithub /></a></li>
                                    <li><a href="https://www.linkedin.com/in/giandemetrio-quattromani-499b4980"><FaLinkedinIn /></a></li>
                                    {/* <li><a href="https://www.amazon.it/gp/profile/amzn1.account.AGJ3Y7NN6EGRHYKM64HMVVVOQ66Q?ie=UTF8"><FaAmazon /></a></li> */}
                                    <li><a href="https://play.google.com/store/apps/dev?id=7481364634238706114"><FaGooglePlay /></a></li>
                                    {/* <li><a href="https://www.facebook.com/NotOnly.it/"><FaFacebook /></a></li> */}
                                    {/* <li><a href="https://www.youtube.com/channel/UCAgeBt5XcHPEiYSgnEM_ucQ"><FaYoutube /></a></li> */}
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="back-to-top">
                <div className="backtop" onClick={handleClick}>
                    <span className="top"></span>
                </div>
            </div>
        </footer>
    )
}
export default Footer
